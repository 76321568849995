import React from 'react';
import Layout from "../../components/Layout/Layout"
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image"
import HomeLayout from '../../components/HomeLayout/HomeLayout';
const Photos = () => {
    
    const data = useStaticQuery(graphql`
    query {
      Photos: allFile(
        sort: { order: ASC, fields: name }
        filter: { sourceInstanceName: { eq: "photos" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
    return (
        <Layout>
            <HomeLayout>
            {
                data.Photos.nodes.map((img ,index)=>{
                    var name =  img.name
                    return (
                        
                    <Link to={`/Gallery/Photos/${name}`} key={index} >
                            <Img fluid={img.childImageSharp.fluid} alt="Photos images" />
                        </Link>
                    )
                })
            }
            </HomeLayout>
        </Layout>
    );
};

export default Photos;